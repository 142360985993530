import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import SearchIcon from '@mui/icons-material/Search'
import { useState, useEffect, useCallback, type RefObject } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Autocomplete } from '@sevenrooms/react-components/components/Autocomplete'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'
import { ButtonGroup } from '@sevenrooms/react-components/components/ButtonGroup'
import { ClickAwayListener } from '@sevenrooms/react-components/components/ClickAwayListener'
import { DateCalendar } from '@sevenrooms/react-components/components/DateCalendar'
import { MenuItem } from '@sevenrooms/react-components/components/MenuItem'
import { Select, type SelectChangeEvent } from '@sevenrooms/react-components/components/Select'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { TextField } from '@sevenrooms/react-components/components/TextField'
import { ToggleButton } from '@sevenrooms/react-components/components/ToggleButton'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { type CalendarViewEnum, filters } from '../../constants'
import { useCalendarControlContext } from '../../hooks'
import { calendarMessages } from '../../locales'
import { titleDateDisplay } from '../../utils'
import { FilterDropdown } from '../FilterDropdown'
import type FullCalendar from '@fullcalendar/react'

interface ControlBarProps {
  calendarRef: RefObject<FullCalendar>
}

export function ControlBar({ calendarRef }: ControlBarProps) {
  const { formatMessage } = useLocales()
  const { calendarView, setCalendarView, dateTitle, setDateTitle, activeFilters, setActiveFilters } = useCalendarControlContext()

  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false)
  const [showAddFilterOptions, setShowAddFilterOptions] = useState<boolean>(false)

  const enabledFilters = filters.filter(item => item.enabled)

  const renderDate = useCallback((date: Date) => titleDateDisplay(date, calendarView), [calendarView])

  const relativeNav = (dir: Number) => {
    if (!calendarRef.current) {
      return
    }
    const calApi = calendarRef.current.getApi()
    switch (dir) {
      case 1:
        calApi.next()
        break
      case 0:
        calApi.today()
        break
      case -1:
        calApi.prev()
        break
      default:
        break
    }
    setDateTitle(renderDate(calApi.getDate()))
  }

  const dateNav = (date: Date) => {
    if (!calendarRef.current) {
      return
    }
    const calApi = calendarRef.current.getApi()
    calApi.gotoDate(date)
    setShowDatePicker(false)
    setDateTitle(renderDate(calApi.getDate()))
  }

  useEffect(() => {
    if (!calendarRef.current) {
      return
    }
    const calApi = calendarRef.current.getApi()
    setDateTitle(renderDate(calApi.getDate()))
  }, [calendarRef, setDateTitle, renderDate])

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: t => t.spacing(4) }}>
        <ButtonGroup variant="outlined" sx={{ maxHeight: '42px', marginRight: t => t.spacing(3) }}>
          <Button
            size="medium"
            data-test="nav-calendar-back"
            onClick={() => relativeNav(-1)}
            sx={{
              maxWidth: '42px',
            }}
          >
            <ChevronLeftIcon fontSize="small" />
          </Button>
          <Button size="medium" data-test="nav-calendar-today" onClick={() => relativeNav(0)}>
            {formatMessage(calendarMessages.todayLabel)}
          </Button>
          <Button
            size="medium"
            data-test="nav-calendar-forward"
            onClick={() => relativeNav(1)}
            sx={{
              maxWidth: '42px',
            }}
          >
            <ChevronRightIcon fontSize="small" />
          </Button>
        </ButtonGroup>
        <ClickAwayListener onClickAway={() => setShowDatePicker(false)}>
          <Box sx={{ marginRight: 'auto', position: 'relative' }}>
            <Stack onClick={() => setShowDatePicker(!showDatePicker)} direction="row" alignItems="center" sx={{ cursor: 'pointer' }}>
              <Typography variant="h4">{dateTitle}</Typography>
              {showDatePicker ? (
                <ExpandLessIcon sx={{ marginLeft: t => t.spacing(3), fontSize: '28px' }} />
              ) : (
                <ExpandMoreIcon sx={{ marginLeft: t => t.spacing(3), fontSize: '28px' }} />
              )}
            </Stack>
            {showDatePicker && (
              <DateCalendar
                onChange={(value: Date) => dateNav(value)}
                referenceDate={calendarRef.current?.getApi().getDate()}
                sx={{
                  background: t => t.palette.common.white,
                  border: t => `1px solid ${t.palette.grey[300]}`,
                  borderRadius: t => t.spacing(1),
                  position: 'absolute',
                  top: t => t.spacing(8),
                  zIndex: 2,
                }}
              />
            )}
          </Box>
        </ClickAwayListener>
        <Select
          value={calendarView}
          onChange={(event: SelectChangeEvent<unknown>) => setCalendarView(event.target.value as CalendarViewEnum)}
          sx={{ minWidth: '110px', marginRight: t => t.spacing(3) }}
        >
          <MenuItem value="dayGridMonth">{formatMessage(calendarMessages.monthLabel)}</MenuItem>
          <MenuItem value="timeGridWeek">{formatMessage(calendarMessages.weekLabel)}</MenuItem>
          <MenuItem value="timeGridDay">{formatMessage(calendarMessages.dayLabel)}</MenuItem>
          <MenuItem value="listWeek">{formatMessage(calendarMessages.listLabel)}</MenuItem>
        </Select>
        <Autocomplete
          id="combo-box-demo"
          freeSolo
          options={['bob', 'nope']}
          sx={{
            width: 300,
          }}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={formatMessage(calendarMessages.searchPlaceholder)}
              slotProps={{
                input: {
                  ...params.InputProps,
                  startAdornment: <SearchIcon />,
                },
              }}
            />
          )}
        />
        <Box>
          <ToggleButton
            data-test="calendar-filter-toggle"
            value="showFilters"
            selected={showFilters}
            color="primary"
            onClick={() => setShowFilters(!showFilters)}
            sx={{
              marginLeft: t => t.spacing(3),
              height: '42px',
              width: '42px',
              borderColor: t => (showFilters ? t.palette.primary.main : t.palette.grey[300]),
            }}
          >
            <FilterAltOutlinedIcon />
          </ToggleButton>
        </Box>
      </Stack>
      {showFilters && (
        <Stack direction="row" alignItems="center" sx={{ marginBottom: t => t.spacing(4) }}>
          {enabledFilters
            .filter(item => activeFilters.includes(item.id))
            .map(activeFilter => (
              <FilterDropdown key={`active-filter-${activeFilter.id}`} filter={activeFilter} />
            ))}
          {enabledFilters.length !== activeFilters.length && (
            <Box sx={{ position: 'relative' }}>
              <Button
                size="medium"
                startIcon={<FilterAltOutlinedIcon />}
                variant="text"
                color="primary"
                data-test="add-calendar-filter"
                onClick={() => setShowAddFilterOptions(!showAddFilterOptions)}
              >
                {formatMessage(calendarMessages.addFilter)}
              </Button>
              {showAddFilterOptions && (
                <ClickAwayListener onClickAway={() => setShowAddFilterOptions(false)}>
                  <Box
                    sx={{
                      background: t => t.palette.common.white,
                      border: t => `1px solid ${t.palette.grey[300]}`,
                      borderRadius: t => t.spacing(1),
                      minWidth: '100%',
                      position: 'absolute',
                      zIndex: 2,
                    }}
                  >
                    {enabledFilters
                      .filter(item => !activeFilters.includes(item.id))
                      .map(filterOption => (
                        <MenuItem
                          key={`add-filter-button-${filterOption.id}`}
                          data-test={`add-filter-${filterOption.id}`}
                          onClick={() => {
                            setActiveFilters([...activeFilters, filterOption.id])
                            setShowAddFilterOptions(false)
                          }}
                        >
                          {formatMessage(filterOption.label)}
                        </MenuItem>
                      ))}
                  </Box>
                </ClickAwayListener>
              )}
            </Box>
          )}
        </Stack>
      )}
    </Box>
  )
}
