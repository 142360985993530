import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { type DateClickArg } from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import AddIcon from '@mui/icons-material/Add'
import { useRef, useCallback, useEffect } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { TimeLocale } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { useTheme } from '@sevenrooms/react-components/hooks/useTheme'
import { CalendarViewEnum } from '../../constants'
import { useCalendarControlContext } from '../../hooks'
import { calendarMessages } from '../../locales'
import { renderOverrides, titleDateDisplay } from '../../utils'
import { ControlBar } from '../ControlBar'
import { EventBlock, EventListItem, ListHeader } from './DisplayOverrides'
import { testEvents } from './testData'
import type { EventContentArg, DayHeaderContentArg } from '@fullcalendar/core'

export function CalendarInterface() {
  const { formatMessage } = useLocales()
  const theme = useTheme()
  const CalendarStyleOverride = renderOverrides(theme)
  const { calendarView, setCalendarView, setDateTitle } = useCalendarControlContext()
  const calendarRef = useRef<InstanceType<typeof FullCalendar>>(null)

  const inlineNav = useCallback(
    (date: Date, e: UIEvent) => {
      const target = (e.currentTarget ? e.currentTarget : e.target) as HTMLElement
      const classes = target.classList
      if (!calendarRef.current || (!classes.contains('fc-daygrid-day-number') && !classes.contains('fc-col-header-cell-cushion'))) {
        return
      }
      const calApi = calendarRef.current.getApi()
      setCalendarView(CalendarViewEnum.DAY)
      calApi.gotoDate(date)
      setDateTitle(titleDateDisplay(date, calendarView))
    },
    [calendarRef, calendarView, setCalendarView, setDateTitle]
  )

  useEffect(() => {
    if (calendarRef.current && calendarView) {
      calendarRef.current.getApi().changeView(calendarView)
    }
  }, [calendarRef, calendarView])

  return (
    <Box>
      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
          borderTop: `1px solid ${theme.palette.grey[200]}`,
          margin: t => `${t.spacing(4)} 0}`,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: t => `${t.spacing(4)} ${t.spacing(8)}`,
          }}
        >
          <Box>
            <Typography variant="h6">{formatMessage(calendarMessages.calendarTitle)}</Typography>
          </Box>
          <Box>
            <Button size="medium" variant="contained" startIcon={<AddIcon />} data-test="add-private-event">
              {formatMessage(calendarMessages.addEvent)}
            </Button>
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          backgroundColor: t => t.palette.common.white,
          minWidth: '800px',
          padding: t => `0 ${t.spacing(8)} ${t.spacing(8)}`,
        }}
      >
        <CalendarStyleOverride />
        <ControlBar calendarRef={calendarRef} />
        {calendarView === CalendarViewEnum.LIST && <ListHeader />}
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          initialView={calendarView}
          eventTextColor={theme.palette.common.black}
          eventBackgroundColor={theme.palette.common.white}
          eventBorderColor={theme.palette.common.white}
          dayMaxEvents={3}
          headerToolbar={false}
          listDayFormat={{
            month: 'short',
            year: 'numeric',
            day: 'numeric',
            weekday: 'long',
          }}
          dayPopoverFormat={{
            month: 'long',
            year: 'numeric',
            day: 'numeric',
            weekday: 'short',
          }}
          listDaySideFormat={false}
          dateClick={({ date, jsEvent }: DateClickArg) => inlineNav(date, jsEvent)}
          views={{
            timeGrid: {
              allDaySlot: false,
              eventContent: (info: EventContentArg) => <EventBlock info={info} />,
              dayHeaderContent: (d: DayHeaderContentArg) => {
                const dayText = new Intl.DateTimeFormat(TimeLocale.getLocale(), { weekday: 'short' }).format(d.date)
                const dayOfMonth = d.date.getDate()
                return { html: `<span class='dow-text'>${dayText}</span><span class='date-text'>${dayOfMonth}</span>` }
              },
              slotLabelFormat: {
                hour: '2-digit',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: 'short',
              },
              navLinks: true,
              navLinkDayClick: (date, jsEvent) => inlineNav(date, jsEvent),
            },
            dayGrid: {
              eventContent: (info: EventContentArg) => <EventBlock info={info} />,
            },
            list: {
              eventContent: (info: EventContentArg) => <EventListItem info={info} />,
            },
          }}
          events={testEvents}
        />
      </Box>
    </Box>
  )
}
