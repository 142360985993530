import { TimeLocale } from '@sevenrooms/core/timepiece'
import type { VenueSettings, ServiceStatus } from '@sevenrooms/mgr-core'
import { CalendarViewEnum } from '../constants'

export const listTime = (start: Date | null, end: Date | null) => {
  const formatter = new Intl.DateTimeFormat(TimeLocale.getLocale(), { hour: 'numeric', minute: 'numeric' })
  const startArr = formatter.format(start || undefined).split(' ')
  const endArr = formatter.format(end || undefined).split(' ')

  if (startArr.length === 2 && startArr[1] === endArr[1]) {
    startArr.pop()
  }

  return `${startArr.join('')}-${endArr.join('')}`.toLowerCase()
}

export const titleDateDisplay = (date: Date, view: CalendarViewEnum) => {
  let dateFormat: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' }
  if (view === CalendarViewEnum.DAY) {
    dateFormat = { ...dateFormat, day: 'numeric' }
  }
  const formatter = new Intl.DateTimeFormat(TimeLocale.getLocale(), dateFormat)
  return formatter.format(date)
}

const Icons: { [key: string]: string } = {
  NOT_RECONCILED: 'booked',
  LEFT_MESSAGE: 'left-message',
  WRONG_NUMBER: 'wrong-number',
  CONFIRMED: 'confirmed',
  PRE_ARRIVED: 'arrived',
  PRE_PARTIALLY_ARRIVED: 'partially-arrived',
  LATE: 'late',
  CANCELED: 'clear',
  NO_SHOW: 'stop',
  NO_ENTRY: 'stop',

  CLOSED: 'checked',
  REQUEST: 'request-circle',
}

export const getStatusParameters = (status: string, vs: VenueSettings) => {
  if (status === 'REQUEST') {
    return { custom: false, initials: 'RQ', display: 'Request', color: '#FFDFB8', icon: Icons.REQUEST }
  }
  const inService = vs.service_statuses['in-service'].reduce((set: string[], item: ServiceStatus) => [...set, item.db_name], [])
  if (inService.includes(status)) {
    return { closed: true, custom: false, initials: '', display: 'Closed', color: '#EEEEEE', icon: Icons.CLOSED }
  }
  const params = vs.service_statuses['pre-service'].filter(item => item.db_name === status)[0] as ServiceStatus
  const initials = params.display
    .split(' ')
    .reduce((item: string, value: string) => item + value.charAt(0), '')
    .toUpperCase()
    .substring(0, 2)
  const icon = Icons[status]

  return { ...params, initials, icon }
}

export const gamma = (hex: string) => {
  const colors = hex.substring(1).match(/.{1,2}/g) ?? []
  const r = parseInt(colors[0] || '0', 16)
  const g = parseInt(colors[1] || '0', 16)
  const b = parseInt(colors[2] || '0', 16)
  return (0.299 * r + 0.587 * g + 0.114 * b) / 255
}
