import { useLocales } from '@sevenrooms/core/locales'
import { useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { useTheme } from '@sevenrooms/react-components/hooks/useTheme'
import { calendarMessages } from '../../locales'
import { listTime, getStatusParameters, gamma } from '../../utils'
import { StatusChip, StatusIcon } from '../Status'
import type { EventContentArg } from '@fullcalendar/core'
import type { ReactNode } from 'react'

const listWidths = [10, 10, 12, 10, 16, 10, 20, 12]

export function EventBlock({ info }: { info: EventContentArg }) {
  const theme = useTheme()
  const { venueSettings } = useVenueSettingsContext()
  const { status } = info.event.extendedProps
  const { color } = getStatusParameters(status, venueSettings)

  return (
    <Box
      sx={{
        backgroundColor: color,
        borderRadius: '4px',
        color: gamma(color) > 0.5 ? theme.palette.common.black : theme.palette.common.white,
        height: '100%',
        overflow: 'hidden',
        padding: t => t.spacing(1),
        width: '100%',
      }}
    >
      <StatusIcon status={status} sx={{ position: 'relative', marginRight: theme.spacing(1) }} />
      <Typography variant="body2" sx={{ display: 'inline' }}>
        <strong>{info.event.title}</strong> {info.timeText}
      </Typography>
    </Box>
  )
}

export function EventListItem({ info }: { info: EventContentArg }) {
  const theme = useTheme()
  const { formatMessage } = useLocales()
  const { venue, space, covers, phone, tables, status } = info.event.extendedProps

  const boxStyle = {
    padding: `${theme.spacing(2)} 1% ${theme.spacing(2)} 0`,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        lineHeight: '16px',
      }}
    >
      <Box sx={{ ...boxStyle, width: `${listWidths[0]}%` }}>{venue}</Box>
      <Box sx={{ ...boxStyle, width: `${listWidths[1]}%` }}>{space}</Box>
      <Box sx={{ ...boxStyle, width: `${listWidths[2]}%` }}>{listTime(info.event.start, info.event.end)}</Box>
      <Box sx={{ ...boxStyle, width: `${listWidths[3]}%` }}>
        {covers} {formatMessage(calendarMessages.guests)}
      </Box>
      <Box sx={{ ...boxStyle, width: `${listWidths[4]}%` }}>
        <strong>{info.event.title}</strong>
      </Box>
      <Box sx={{ ...boxStyle, width: `${listWidths[5]}%` }}>{phone}</Box>
      <Box sx={{ ...boxStyle, width: `${listWidths[6]}%` }}>{tables}</Box>
      <Box sx={{ ...boxStyle, width: `${listWidths[7]}%`, color: `${theme.palette.text.primary}` }}>
        <StatusChip status={status} />
      </Box>
    </Stack>
  )
}

export function ListHeader() {
  const theme = useTheme()
  const { formatMessage } = useLocales()
  const headers = [
    formatMessage(calendarMessages.listHeaderVenue),
    formatMessage(calendarMessages.listHeaderSpace),
    formatMessage(calendarMessages.listHeaderTime),
    formatMessage(calendarMessages.listHeaderCovers),
    formatMessage(calendarMessages.listHeaderEventName),
    formatMessage(calendarMessages.listHeaderPhone),
    formatMessage(calendarMessages.listHeaderTables),
    formatMessage(calendarMessages.listHeaderStatus),
  ]
  const headerDisplay: ReactNode[] = []
  headers.forEach((title, index) => {
    headerDisplay.push(
      <Box key={`list-header-${title}`} sx={{ width: `${listWidths[index]}%` }}>
        {title}
      </Box>
    )
  })
  return (
    <Stack
      direction="row"
      sx={{
        color: t => t.palette.grey[600],
        fontSize: '10px',
        letterSpacing: '0.15em',
        padding: `${theme.spacing(2)} 0`,
        textTransform: 'uppercase',
      }}
    >
      {headerDisplay}
    </Stack>
  )
}
