import { useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { useTheme } from '@sevenrooms/react-components/hooks/useTheme'
import { getStatusParameters, gamma } from '../../utils'
import { StatusIcon } from './StatusIcon'

export function StatusChip({ status }: { status: string }) {
  const { venueSettings } = useVenueSettingsContext()
  const theme = useTheme()
  const { color, closed, display } = getStatusParameters(status, venueSettings)
  const gammaCorrection = gamma(color) > 0.5 ? theme.palette.common.black : theme.palette.common.white

  return (
    <Box sx={{ display: 'inline-block' }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          background: color,
          border: color.toUpperCase() === '#FFFFFF' ? `1px solid #EEEEEE` : 'none',
          borderRadius: t => t.spacing(3),
          boxSizing: 'border-box',
          color: closed ? theme.palette.text.secondary : gammaCorrection,
          height: t => t.spacing(6),
          marginRight: 'auto',
          padding: t => `0 ${t.spacing(3)} 0 ${t.spacing(1)}`,
        }}
      >
        <StatusIcon status={status} />
        <Typography
          sx={{
            fontSize: '12px',
            marginLeft: t => t.spacing(1),
            textTransform: 'capitalize',
          }}
        >
          {display}
        </Typography>
      </Stack>
    </Box>
  )
}
