import { useState } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'
import { MenuItem } from '@sevenrooms/react-components/components/MenuItem'
import { MultiSelectTree } from '@sevenrooms/react-components/components/MultiSelectTree'
import { Select } from '@sevenrooms/react-components/components/Select'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { calendarMessages } from '../../locales'
import type { Filter } from '../../constants'

interface FilterDropdownProps {
  filter: Filter
}

interface FilterItem {
  id: string
  label: string
  children?: FilterItem[]
}

export function FilterDropdown({ filter }: FilterDropdownProps) {
  const { formatMessage } = useLocales()
  const [selected, setSelected] = useState<string[]>([])
  const [filterOpen, setFilterOpen] = useState<boolean>(false)

  const title = formatMessage(filter.label)

  const items = [
    {
      id: '1',
      label: 'Catch',
    },
    {
      id: '2',
      label: '1Oak',
      children: [
        {
          id: '21',
          label: 'Mary',
        },
        {
          id: '22',
          label: 'Contrary',
        },
      ],
    },
    {
      id: '3',
      label: 'La Esquina',
    },
  ]

  const flatRef: Array<FilterItem> = []
  function flattenItems(arr: FilterItem[]) {
    arr.forEach((item: FilterItem) => {
      flatRef.push({ id: item.id, label: item.label })
      if (item.children) {
        flattenItems(item.children)
      }
    })
  }
  flattenItems(items)

  const Actions = (
    <Stack direction="row">
      <Button
        size="medium"
        variant="text"
        data-test={`filter-select-all-${title}`}
        onClick={() => setSelected(flatRef.reduce((arr: Array<string>, item: FilterItem) => [...arr, item.id], []))}
      >
        Select All
      </Button>
      <Button size="medium" variant="text" onClick={() => setSelected([])} data-test={`filter-clear-${title}`} sx={{ marginRight: 'auto' }}>
        Clear
      </Button>
      <Button size="medium" variant="contained" data-test={`filter-apply-${title}`}>
        Apply
      </Button>
    </Stack>
  )

  return (
    <Box sx={{ marginRight: t => t.spacing(3), position: 'relative' }}>
      <Select value="display" onOpen={() => setFilterOpen(!filterOpen)} open={false}>
        <MenuItem value="display">
          {title}
          {selected.length ? (
            <>
              :{' '}
              <strong>
                {selected.length === 1 ? (
                  flatRef?.filter(item => item.id === selected[0])[0]?.label
                ) : (
                  <Box
                    sx={{
                      display: 'inline-block',
                      background: t => t.palette.grey[300],
                      borderRadius: '9px',
                      minWidth: '10px',
                      padding: '0 4px',
                      height: '18px',
                      lineHeight: '18px',
                      textAlign: 'center',
                      fontSize: '11px',
                    }}
                  >
                    {selected.length}
                  </Box>
                )}
              </strong>
            </>
          ) : null}
        </MenuItem>
      </Select>
      <MultiSelectTree
        items={items}
        data-test={`filter-select-${filter.id}`}
        filterLabelText={title}
        noItemsFoundText={formatMessage(calendarMessages.noMatches)}
        dialogTitle={title}
        onSelectedIdsChange={ids => setSelected(ids.selectedIds)}
        selectedIds={selected}
        mulitSelectTreeActions={Actions}
        sx={{ width: '100%', position: 'absolute', top: 0 }}
      />
    </Box>
  )
}
