import { getDateFNSLocale } from '@sevenrooms/core/timepiece'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { Box } from '@sevenrooms/react-components/components/Box'
import { LocalizationProvider } from '@sevenrooms/react-components/components/LocalizationProvider'
import { CalendarControlContext } from '../../hooks'
import { CalendarInterface } from '../CalendarInterface'

export function PrivateEventsCalendar() {
  const { venueLocale } = useAppContext()
  const locale = getDateFNSLocale(venueLocale)
  return (
    <CalendarControlContext>
      <LocalizationProvider adapterLocale={locale}>
        <Box
          sx={{
            backgroundColor: t => t.palette.common.white,
            minWidth: '800px',
          }}
        >
          <CalendarInterface />
        </Box>
      </LocalizationProvider>
    </CalendarControlContext>
  )
}
