import { defineMessages } from '@sevenrooms/core/locales'

export const calendarMessages = defineMessages({
  calendarTitle: {
    id: 'calendar.calendarTitle',
    defaultMessage: 'Private Events Calendar',
  },
  listHeaderVenue: {
    id: 'calendar.listHeader',
    defaultMessage: 'Venue',
  },
  listHeaderSpace: {
    id: 'calendar.listHeaderSpace',
    defaultMessage: 'Space',
  },
  listHeaderTime: {
    id: 'calendar.listHeaderTime',
    defaultMessage: 'Time',
  },
  listHeaderCovers: {
    id: 'calendar.listHeaderCovers',
    defaultMessage: 'Covers',
  },
  listHeaderEventName: {
    id: 'calendar.listHeaderEventName',
    defaultMessage: 'Event name',
  },
  listHeaderPhone: {
    id: 'calendar.listHeaderPhone',
    defaultMessage: 'Phone',
  },
  listHeaderTables: {
    id: 'calendar.listHeaderTables',
    defaultMessage: 'Tables',
  },
  listHeaderStatus: {
    id: 'calendar.listHeaderStatus',
    defaultMessage: 'Status',
  },
  guests: {
    id: 'calendar.guests',
    defaultMessage: 'guests',
  },
  addEvent: {
    id: 'calendar.addEvent',
    defaultMessage: 'Add Event',
  },
  addFilter: {
    id: 'calendar.addFilter',
    defaultMessage: 'Add Filter',
  },
  monthLabel: {
    id: 'calendar.monthLabel',
    defaultMessage: 'Month',
  },
  weekLabel: {
    id: 'calendar.weekLabel',
    defaultMessage: 'Week',
  },
  dayLabel: {
    id: 'calendar.dayLabel',
    defaultMessage: 'Day',
  },
  listLabel: {
    id: 'calendar.listLabel',
    defaultMessage: 'List',
  },
  todayLabel: {
    id: 'calendar.todayLabel',
    defaultMessage: 'Today',
  },
  statusBooked: {
    id: 'calendar.statusBooked',
    defaultMessage: 'Booked',
  },
  statusPending: {
    id: 'calendar.statusPending',
    defaultMessage: 'Pending Details',
  },
  statusConfirmed: {
    id: 'calendar.statusConfirmed',
    defaultMessage: 'Confirmed',
  },
  statusHold: {
    id: 'calendar.statusHold',
    defaultMessage: 'Hold',
  },
  statusRequest: {
    id: 'calendar.statusRequest',
    defaultMessage: 'Request',
  },
  statusCancelled: {
    id: 'calendar.statusCancelled',
    defaultMessage: 'Cancelled',
  },
  statusClosed: {
    id: 'calendar.statusClosed',
    defaultMessage: 'Closed',
  },
  noMatches: {
    id: 'calendar.noMatches',
    defaultMessage: 'No matches found',
  },
  searchPlaceholder: {
    id: 'calendar.searchPlaceholder',
    defaultMessage: 'Search Name, Email, Phone',
  },
  filterLabelVenue: {
    id: 'calendar.filterLabelVenue',
    defaultMessage: 'Venue',
  },
  filterLabelSpace: {
    id: 'calendar.filterLabelSpace',
    defaultMessage: 'Space',
  },
  filterLabelStatus: {
    id: 'calendar.filterLabelStatus',
    defaultMessage: 'Status',
  },
  filterLabelBookedBy: {
    id: 'calendar.filterLabelBookedBy',
    defaultMessage: 'Booked By',
  },
  filterLabelManager: {
    id: 'calendar.filterLabelManager',
    defaultMessage: 'Manager',
  },
  filterLabelSeatingArea: {
    id: 'calendar.filterLabelSeatingArea',
    defaultMessage: 'Seating Area',
  },
} as const)
