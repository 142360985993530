import { calendarMessages as messages } from '../locales'

export interface Filter {
  id: string
  label: { id: string; defaultMessage: string }
  enabled: boolean
}

export const filters: Filter[] = [
  {
    id: 'venue',
    label: messages.filterLabelVenue,
    enabled: true,
  },
  {
    id: 'space',
    label: messages.filterLabelSpace,
    enabled: true,
  },
  {
    id: 'status',
    label: messages.filterLabelStatus,
    enabled: true,
  },
  {
    id: 'booked-by',
    label: messages.filterLabelBookedBy,
    enabled: false,
  },
  {
    id: 'manager',
    label: messages.filterLabelManager,
    enabled: false,
  },
  {
    id: 'seating-area',
    label: messages.filterLabelSeatingArea,
    enabled: false,
  },
]
