import { useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { Box } from '@sevenrooms/react-components/components/Box'
import { type SxProps, useTheme } from '@sevenrooms/react-components/hooks/useTheme'
import { getStatusParameters, gamma } from '../../utils'

export function StatusIcon({ status, sx }: { status: string; sx?: SxProps }) {
  const { venueSettings } = useVenueSettingsContext()
  const { color, custom, initials, icon } = getStatusParameters(status, venueSettings)

  const theme = useTheme()
  const customBackground = gamma(color) > 0.5 ? theme.palette.common.black : theme.palette.common.white

  const customAdjustment: SxProps = custom
    ? {
        backgroundColor: customBackground,
        borderRadius: '50%',
        color,
        fontSize: 10,
        height: 16,
        lineHeight: '16px',
        textAlign: 'center',
        width: 16,
      }
    : {}

  return (
    <Box
      sx={{
        display: 'inline-block',
        fontSize: 16,
        ...customAdjustment,
        ...sx,
      }}
    >
      {custom || !icon ? initials : <i className={`svr-icon- svr-icon--${icon}`} />}
    </Box>
  )
}
