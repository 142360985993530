import constate from 'constate'
import { useState } from 'react'
import { CalendarViewEnum } from '../constants'

function useCalendarControl() {
  const [calendarView, setCalendarView] = useState<CalendarViewEnum>(CalendarViewEnum.LIST)
  const [dateTitle, setDateTitle] = useState<string | null>(null)
  const [activeFilters, setActiveFilters] = useState<Array<string>>(['venue', 'space', 'status'])

  return {
    calendarView,
    setCalendarView,
    dateTitle,
    setDateTitle,
    activeFilters,
    setActiveFilters,
  }
}

export const [CalendarControlContext, useCalendarControlContext] = constate(useCalendarControl)
